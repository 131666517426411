.article-list {
    display: grid;
    gap: 1rem;
    /*padding: 1rem;*/
    justify-content: center;
    margin: 0 auto;
}

.article-preview {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.article-preview:hover {
    background-color: lightgray;
}

.article-title {
    margin: 0 0 1rem;
    font-size: 1.25rem;
}

.article-excerpt {
    position: relative;
}

.article-excerpt p {
    margin: 0;
}

.article-excerpt::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
}

.read-more-btn {
    display: inline-block;
    background-color: rgb(2, 120, 174);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none;
    margin-top: 20px;
}

.read-more-btn:hover {
    background-color: rgb(2, 120, 200);
}

.reading-time-container {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align reading time to the right */
}

.material-icons {
    color: rgb(2, 120, 174);
    vertical-align: middle;
    margin-right: 4px;
}

.reading-time {
    color: #757575;
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
}

.reading-time-container .material-icons {
    color: gray !important;
}

.article-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}
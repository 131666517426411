body {
    /*background-color: #f2f3f3;*/
    font-family: source-serif-pro, Georgia, Cambria, "Times New Roman", Times, serif;
    line-height: 1.6;
    color: #333;
    margin: 0 auto;
}

.wrapper {
    display: flex;
    flex-direction: column;
    margin: 70px auto 0 0;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 70px);
}

.content-wrapper {
    max-width: 800px;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 10px;
    align-self: center;
}

.content-item {
    background-color: white;
    padding: 1rem;
}

.ad-container.left {
    left: 0;
}

.ad-container.right {
    right: 0;
}

.ad-container {
    min-width: 150px;
    min-height: 100px;
    padding: 1rem;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.ad-slot {
    background-color: #e0e0e0;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    /*border: 1px solid #ccc;*/
}

.adsbygoogle {
    display: block;
}
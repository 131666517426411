h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #222;
    margin-top: 2rem;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

h4, h5, h6 {
    font-size: 1.25rem;
}

p {
    margin-top: 1rem;
    font-size: 20px;
    color: rgba(41, 41, 41, 1);
}

a {
    color: #1a73e8;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1rem 0;
}

ul, ol {
    padding-left: 2rem;
}

blockquote {
    border-left: 4px solid #ddd;
    padding-left: 1rem;
    font-style: italic;
    color: #777;
    margin: 1rem 0;
}

pre {
    background-color: #f4f4f4;
    padding: 1rem;
    overflow-x: auto;
}

code {
    font-family: 'Courier New', monospace;
    font-size: 0.9rem;
    background-color: #f4f4f4;
    padding: 0.25rem;
}

hr {
    border: none;
    border-top: 1px solid #ddd;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.article-container {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1600px) {
    .article-container {
        flex-direction: column;
    }
}

.content-item {
    flex-grow: 3;
    max-width: 100%;
}

@media screen and (max-width: 1600px) {
    .content-item {
        max-width: 100%;
    }
}

.header-list {
    margin-left: 0;
    padding-left: 1rem;
    border-left: 1px solid #ddd;
}

.header-list ul {
    list-style-type: none;
    padding: 0;
}

.header-list li {
    margin-bottom: 0.5rem;
}

.header-list a {
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.833rem;
}

.header-list a:hover {
    text-decoration: underline;
}

.toc-container {
    position: fixed;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    width: 300px;
    /*background-color: #f2f3f3;*/
    overflow: hidden;
}

@media screen and (max-width: 1850px){
    .toc-container {
        visibility: hidden;
    }
}